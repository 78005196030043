/** @module lib/core/loadSection */

import type { ParamsValues } from 'src/lib/entities/GraphNode'
import type { LegacySection, Section, SectionEvaluations, SectionParameters, ValidateParametersFn } from 'src/lib/types'

type SectionLoader = () => Promise<Section | LegacySection>
// ATTENTION à garder ce fichier synchrone avec src/legacy/core/adresses.js (de la v1)

const sections: Record<string, SectionLoader> = {
  // des pseudos sections d’exemple
  Exemple: (): Promise<LegacySection> => import('src/legacy/sections/exemples/sectionExemple.js'),
  // exValidationZones: 'exemples',
  // testClavierVirtuel: 'exemples',

  // des sections à réparer / ranger
  diviseursdirect: (): Promise<LegacySection> => import('src/legacy/sections/vrac/sectiondiviseursdirect.js'),
  GdPrixSym: (): Promise<LegacySection> => import('src/legacy/sections/vrac/sectionGdPrixSym.js'),

  alea: (): Promise<LegacySection> => import('src/legacy/sections/outils/sectionalea.js'),
  basthonConsole: (): Promise<LegacySection> => import('src/legacy/sections/NSI/sectionbasthonConsole.js'),
  patron01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/patron/sectionpatron01.js'),
  fonction01: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/fonctions/sectionfonction01.js'),
  fonction03: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/fonctions/sectionfonction03.js'),
  fonction04: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/fonctions/sectionfonction04.js'),
  fonction05: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/fonctions/sectionfonction05.js'),
  fonction02: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/fonctions/sectionfonction02.js'),
  trianglessemblables01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/trianglesegaux/sectiontrianglessemblables01.js'),
  trianglessemblables02: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/trianglesegaux/sectiontrianglessemblables02.js'),
  trianglessemblables03: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/trianglesegaux/sectiontrianglessemblables03.js'),
  trianglessemblables04: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/trianglesegaux/sectiontrianglessemblables04.js'),
  diag01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/unitcomp/sectiondiag01.js'),
  trianglesegaux01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/trianglesegaux/sectiontrianglesegaux01.js'),
  trianglesegaux02: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/trianglesegaux/sectiontrianglesegaux02.js'),
  trianglesegaux03: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/trianglesegaux/sectiontrianglesegaux03.js'),
  trianglesegaux04: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/trianglesegaux/sectiontrianglesegaux04.js'),
  puissancesSimple: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/puissances/sectionpuissancesSimple.js'),
  formuleMake: (): Promise<LegacySection> => import('src/legacy/sections/college/formule/sectionformuleMake.js'),
  etikMake: (): Promise<LegacySection> => import('src/legacy/sections/college/etikMake/sectionetikMake.js'),
  puissancesdef: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/puissances/sectionpuissancesdef.js'),
  prefixes01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/prefixes/sectionprefixes01.js'),
  puissancesdef10: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/puissances/sectionpuissancesdef10.js'),
  ReciproquePythagore: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/pythagore/sectionReciproquePythagore.js'),
  conunitcomp: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/unitcomp/sectionconunitcomp.js'),
  pyth01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/pythagore/sectionpyth01.js'),
  repere01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/repere/sectionrepere01.js'),
  proporgraph: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/proportionnalite/sectionproporgraph.js'),
  conserve01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/transformations/sectionconserve01.js'),
  airevolagrand: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/transformations/sectionairevolagrand.js'),
  compose01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/transformations/sectioncompose01.js'),
  recon01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/transformations/sectionrecon01.js'),
  represnomb01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/nomb/sectionrepresnomb01.js'),
  represnomb02: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/nomb/sectionrepresnomb02.js'),
  fractioncalculs: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/fractions/sectionfractioncalculs.js'),
  nature: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/litteral/sectionnature.js'),
  prog01: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/literal/sectionprog01.js'),
  comprop: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/proport/sectioncomprop.js'),
  ratio01: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/proport/sectionratio01.js'),
  nomme: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionnomme.js'),
  nommeangles: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionnommeangles.js'),
  distptdrt: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Droites/sectiondistptdrt.js'),
  // pas trouvé ce qui plaît pas à tsc dans ce qu’exporte cette section
  // copier coller le tableau parametres dans un fichier ts avec
  // `const parametres: LegacySectionParamsParametres = [ … ]
  // ne pose pas de pb à tsc mais ça passe pas ici…
  stat01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/sectionstat01.js') as unknown as Promise<LegacySection>,
  contraire01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/alea/sectioncontraire01.js'),
  blokmtg01: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/mtg/sectionblokmtg01'),
  bloksim01: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/simulation/sectionbloksim01.js'),
  blokmohascratch: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/scratchmake/sectionblokmohascratch.js'),
  blokmtglibre: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/mtg/sectionblokmtglibre.js'),
  blokcond: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/si/simple/sectionblokcond.js'),
  blokformules: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/calculs/formules/sectionblokformules.js'),
  blokblok01: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/blok/sectionblokblok01.js'),
  blokblok02: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/blok/sectionblokblok02.js'),
  blokblok03: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/blok/sectionblokblok03.js'),
  blok07: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/variable/injecter/sectionblok07.js'),
  blokvar: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/variable/injecter/sectionblokvar.js'),
  blok08: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/variable/utilisateur/sectionblok08.js'),
  blok11: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/calculs/blok11/sectionblok11.js'),
  blok09: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/calculs/blok09/sectionblok09.js'),
  blok10: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/alea/blok10/sectionblok10.js'),
  blokligne: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/dessin/ligne/sectionblokligne.js'),
  blokfigure: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/dessin/figure/sectionblokfigure.js'),
  blokfuseeordre: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/fusee/ordre/sectionblokfuseeordre.js'),
  blokfuseeobjectif: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/fusee/objectif/sectionblokfuseeobjectif.js'),
  blokfuseeboucle: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/fusee/boucle/sectionblokfuseeboucle.js'),
  blokfuseesi: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/fusee/si/sectionblokfuseesi.js'),
  blokbrasordre: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/bras/ordre/sectionblokbrasordre.js'),
  blokbrasobjectif: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/bras/objectif/sectionblokbrasobjectif.js'),
  blokcomparealea: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/si/comparealea/sectionblokcomparealea.js'),
  blokProgCalcul01: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/programmeCalculs/sectionblokProgCalcul01.js'),
  perimeairequad: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/perimaire/sectionperimeairequad.js'),
  decoupe01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/perimaire/sectiondecoupe01.js'),
  relatifsuneop: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/relatifs/sectionrelatifsuneop.js'),
  sommealgebrique: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/relatifs/sectionsommealgebrique.js'),
  parentheses: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/relatifs/sectionparentheses.js'),
  bloksisimple: (): Promise<LegacySection> => import('src/legacy/sections/college/blockly/si/simple/sectionbloksisimple.js'),
  nomme02: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionnomme02.js'),
  nomme03: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionnomme03.js'),
  point: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionpoint.js'),
  appartenance: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionappartenance.js'),
  progconstruction: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/outilsgeo/sectionprogconstruction.js'),
  progconstructionlibre: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/outilsgeo/sectionprogconstructionlibre.js'),
  alignement: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionalignement.js'),
  multiplevoc: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/multiples/sectionmultiplevoc.js'),
  angle01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionangle01.js'),
  angle02: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionangle02.js'),
  fraction16: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/proportionalite/sectionfraction16.js'),
  eqprod: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/equations/sectioneqprod.js'),
  angle03: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionangle03.js'),
  angle04: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionangle04.js'),
  equerre01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionequerre01.js'),
  conversion: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Conversion/sectionconversion.js'),
  volumecompt: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/volume/sectionvolumecompt.js'),
  perspe01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/volume/sectionperspe01.js'),
  complete01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/symetrie/sectioncomplete01.js'),
  conversionbase: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Conversion/sectionconversionbase.js'),
  point02: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Elementgeo/sectionpoint02.js'),
  reduiresimple: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/sectionreduiresimple.js'),
  thales01: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/thales/sectionthales01.js'),
  thales02: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/thales/sectionthales02.js'),
  recipThales: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/thales/sectionrecipThales.js'),
  symetriequad: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/symetrie/sectionsymetriequad.js'),
  decompose01: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/arithmetique/sectiondecompose01.js'),
  decompose02: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/arithmetique/sectiondecompose02.js'),
  decompositionLibre: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/arithmetique/sectiondecompositionLibre.js'),
  premier01: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/arithmetique/sectionpremier01.js'),
  trigo01: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/trigo/sectiontrigo01.js'),
  // existe plus
  // '5eairetriangle': (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/section5eairetriangle.js'),
  // remplace 5priorites30 qui était dans src/legacy/sections/EssaisXavier/section5priorites30.js
  priorites: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/sectionpriorites.js'),
  memoMake: (): Promise<LegacySection> => import('src/legacy/sections/college/sectionmemoMake.js'),
  pixelMake: (): Promise<LegacySection> => import('src/legacy/sections/college/pixel/sectionpixelMake.js'),
  resMake: (): Promise<LegacySection> => import('src/legacy/sections/college/resMake/sectionresMake.js'),
  resMake2: (): Promise<LegacySection> => import('src/legacy/sections/college/testDeporte/sectionresMake2.js'),
  fraction10: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction10.js'),
  fraction11: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction11.js'),
  fraction12: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction12.js'),
  fraction13: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction13.js'),
  fraction14: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction14.js'),
  fraction15: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction15.js'),
  airesecteur: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/Aire/sectionairesecteur.js'),
  tableur01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/tableur/sectiontableur01.js'),
  testeq: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/equations/sectiontesteq.js'),
  rezeq: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/equations/sectionrezeq.js'),
  rezeq02: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/equations/sectionrezeq02.js'),
  miseeneq01: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/equations/sectionmiseeneq01.js'),
  ineg01: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/ineg/sectionineg01.js'),
  develop01: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/literal/sectiondevelop01.js'),
  develop02: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/literal/sectiondevelop02.js'),
  stat02: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/stats/sectionstat02.js'),
  stat03: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/stats/sectionstat03.js'),
  racine01: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/racine/sectionracine01.js'),
  priorite01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Operations/sectionpriorite01.js'),
  poseoperation: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Operations/sectionposeoperation.js'),
  factorise01: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/literal/sectionfactorise01.js'),
  remplace: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/literal/sectionremplace.js'),
  demanglecorres01: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/alternesinternes/sectiondemanglecorres01.js'),
  sommeangle: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/sommeangles/sectionsommeangle.js'),
  comparerelatifs: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/relatifs/sectioncomparerelatifs.js'),
  relatifsoppose01: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/relatifs/sectionrelatifsoppose01.js'),
  distance01: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/relatifs/sectiondistance01.js'),
  airelateraleprismecylindre: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/Aire/sectionairelateraleprismecylindre.js'),
  appartenancecercle: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/cercle/sectionappartenancecercle.js'),
  multdiv10base: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Operations/sectionmultdiv10base.js'),
  valeurapprochee: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Decimaux/sectionvaleurapprochee.js'),
  bienecrire: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Decimaux/sectionbienecrire.js'),
  compare06: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Decimaux/sectioncompare06.js'),
  ecrirechiffres: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Decimaux/sectionecrirechiffres.js'),
  encadrer01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Decimaux/sectionencadrer01.js'),
  fraction01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction01.js'),
  fraction02: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction02.js'),
  fraction03: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction03.js'),
  fraction04: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction04.js'),
  fraction05: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction05.js'),
  fraction06: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction06.js'),
  fraction07: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction07.js'),
  fraction08: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction08.js'),
  fraction09: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Fractions/sectionfraction09.js'),
  ordredegrandeur: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Operations/sectionordredegrandeur.js'),
  formules: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/Formules/sectionformules.js'),
  formules02: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/Formules/sectionformules02.js'),
  sphere01: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/sphere/sectionsphere01.js'),
  sphere02: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/sphere/sectionsphere02.js'),
  section01: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/sections/sectionsection01.js'),
  section02: (): Promise<LegacySection> => import('src/legacy/sections/college/troisieme/sections/sectionsection02.js'),
  probleme: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Problemes/sectionprobleme.js'),
  probleme02: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Problemes/sectionprobleme02.js'),
  proportionnalite01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Proportionnalite/sectionproportionnalite01.js'),
  proportionnalite02: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Proportionnalite/sectionproportionnalite02.js'),
  propor03: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Proportionnalite/sectionpropor03.js'),
  agrand01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Proportionnalite/sectionagrand01.js'),
  dem01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Droites/sectiondem01.js'),
  med01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Droites/sectionmed01.js'),
  med02: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Droites/sectionmed02.js'),
  paraperp: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Droites/sectionparaperp.js'),
  quad01: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/Quadrilateres/sectionquad01.js'),
  quad02: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/Quadrilateres/sectionquad02.js'),
  quad03: (): Promise<LegacySection> => import('src/legacy/sections/college/cinquieme/Quadrilateres/sectionquad03.js'),
  choix: (): Promise<LegacySection> => import('src/legacy/sections/college/sectionchoix.js'),
  // existent plus
  // '3D': (): Promise<LegacySection> => import('src/legacy/sections/outils/section3D.js'),
  // '3DLaurent': (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/section3DLaurent.js'),
  EtudeEquaDiff: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctionsetude/sectionEtudeEquaDiff.js'),
  EtudeFonction_limite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctionsetude/sectionEtudeFonction_limite.js'),
  EtudeFonction_derivee: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctionsetude/sectionEtudeFonction_derivee.js'),
  EtudeFonction_facteursderivee: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctionsetude/sectionEtudeFonction_facteursderivee.js'),
  EtudeFonction_signederivee: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctionsetude/sectionEtudeFonction_signederivee.js'),
  EtudeFonction_variations: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctionsetude/sectionEtudeFonction_variations.js'),
  compteestbon: (): Promise<LegacySection> => import('src/legacy/sections/jeux/sectioncompteestbon.js'),
  sokoban: (): Promise<LegacySection> => import('src/legacy/sections/jeux/sectionsokoban.js'),
  501: (): Promise<LegacySection> => import('src/legacy/sections/jeux/section501.js'),
  justeprix: (): Promise<LegacySection> => import('src/legacy/sections/jeux/sectionjusteprix.js'),
  // ALEX : section qui ne devrait pas être visible telle quel...
  // JP : ajouter un DIV contenant le QCM avec des param de mise en forme. Pour l’instant il est dans MG'
  squelettepsylvia: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettepsylvia.js'),
  squelettepresentation: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettepresentation.js'),
  opePrioritaire: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/Operations/sectionopePrioritaire.js'),
  simulation: (): Promise<LegacySection> => import('src/legacy/sections/lycee/echantillonage/sectionsimulation.js'),
  intervalleConf: (): Promise<LegacySection> => import('src/legacy/sections/lycee/echantillonage/sectionintervalleConf.js'),
  progCalculBloc: (): Promise<LegacySection> => import('src/legacy/sections/lycee/algorithmique/sectionprogCalculBloc.js'),
  progSiBloc: (): Promise<LegacySection> => import('src/legacy/sections/lycee/algorithmique/sectionprogSiBloc.js'),
  variablesAlgo: (): Promise<LegacySection> => import('src/legacy/sections/lycee/algorithmique/sectionvariablesAlgo.js'),
  InstructionSi: (): Promise<LegacySection> => import('src/legacy/sections/lycee/algorithmique/sectionInstructionSi.js'),
  InstructionPour: (): Promise<LegacySection> => import('src/legacy/sections/lycee/algorithmique/sectionInstructionPour.js'),
  InstructionTantque: (): Promise<LegacySection> => import('src/legacy/sections/lycee/algorithmique/sectionInstructionTantque.js'),
  algoAirePerimetre: (): Promise<LegacySection> => import('src/legacy/sections/lycee/algorithmique/sectionalgoAirePerimetre.js'),
  commandePython: (): Promise<LegacySection> => import('src/legacy/sections/lycee/algorithmique/sectioncommandePython.js'),
  critereDivisibilite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/arithmetique/sectioncritereDivisibilite.js'),
  resteDivEucl: (): Promise<LegacySection> => import('src/legacy/sections/lycee/arithmetique/sectionresteDivEucl.js'),
  pgcdAvecDecomposition: (): Promise<LegacySection> => import('src/legacy/sections/lycee/arithmetique/sectionpgcdAvecDecomposition.js'),
  simpleFracAvecDecomp: (): Promise<LegacySection> => import('src/legacy/sections/lycee/arithmetique/sectionsimpleFracAvecDecomp.js'),
  pgcdAvecAlgoEuclide: (): Promise<LegacySection> => import('src/legacy/sections/lycee/arithmetique/sectionpgcdAvecAlgoEuclide.js'),
  lecture_nbderive: (): Promise<LegacySection> => import('src/legacy/sections/lycee/derivation/sectionlecture_nbderive.js'),
  signe_derivee: (): Promise<LegacySection> => import('src/legacy/sections/lycee/derivation/sectionsigne_derivee.js'),
  convexiteDerivee: (): Promise<LegacySection> => import('src/legacy/sections/lycee/derivation/sectionconvexiteDerivee.js'),
  convexiteGraphique: (): Promise<LegacySection> => import('src/legacy/sections/lycee/derivation/sectionconvexiteGraphique.js'),
  solutionEquaDiff: (): Promise<LegacySection> => import('src/legacy/sections/lycee/derivation/sectionsolutionEquaDiff.js'),
  inegaliteConvexite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/derivation/sectioninegaliteConvexite.js'),
  produitscalaire: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectionproduitscalaire.js'),
  distancePtPlan: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectiondistancePtPlan.js'),
  vectCoplanaires: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectionvectCoplanaires.js'),
  affineounon: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fctsaffines/sectionaffineounon.js'),
  variations_affine: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fctsaffines/sectionvariations_affine.js'),
  DroiteTableauValeurs: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fctsaffines/sectionDroiteTableauValeurs.js'),
  lectureEquationDroite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fctsaffines/sectionlectureEquationDroite.js'),
  homographique_domaine: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fctshomographiques/sectionhomographique_domaine.js'),
  homographique_expression: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fctshomographiques/sectionhomographique_expression.js'),
  zero_homographique: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fctshomographiques/sectionzero_homographique.js'),
  inequation_homographiques: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fctshomographiques/sectioninequation_homographiques.js'),
  inegalite_fctref: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctions_reference/sectioninegalite_fctref.js'),
  uplusk_ku: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctions_reference/sectionuplusk_ku.js'),
  distance_valabs: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctions_reference/sectiondistance_valabs.js'),
  distance_reels: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctions_reference/sectiondistance_reels.js'),
  resolution_valabs: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctions_reference/sectionresolution_valabs.js'),
  inequationFctRef: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctions_reference/sectioninequationFctRef.js'),
  encadreFctRef: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctions_reference/sectionencadreFctRef.js'),
  courbeParite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctions_reference/sectioncourbeParite.js'),
  propFctsTrigo: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctions_reference/sectionpropFctsTrigo.js'),
  kFoisAPuisX: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctions_reference/sectionkFoisAPuisX.js'),
  inequations1: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Inequations/sectioninequations1.js'),
  inequations2: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Inequations/sectioninequations2.js'),
  inequationdomaine: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Inequations/sectioninequationdomaine.js'),
  inegalite_intervalle: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Inequations/sectioninegalite_intervalle.js'),
  signeInegalites: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Inequations/sectionsigneInegalites.js'),
  inegalitePhrase: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Inequations/sectioninegalitePhrase.js'),
  intervallesUnionInter: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Inequations/sectionintervallesUnionInter.js'),
  imageAntecedents: (): Promise<LegacySection> => import('src/legacy/sections/lycee/lecturesGraphiques/sectionimageAntecedents.js'),
  ExtremumLecture: (): Promise<LegacySection> => import('src/legacy/sections/lycee/lecturesGraphiques/sectionExtremumLecture.js'),
  tabVarLecture: (): Promise<LegacySection> => import('src/legacy/sections/lycee/lecturesGraphiques/sectiontabVarLecture.js'),
  equationFctsLecture: (): Promise<LegacySection> => import('src/legacy/sections/lycee/lecturesGraphiques/sectionequationFctsLecture.js'),
  comparaisonTableau: (): Promise<LegacySection> => import('src/legacy/sections/lycee/lecturesGraphiques/sectioncomparaisonTableau.js'),
  IntersectionDroitesAxes: (): Promise<LegacySection> => import('src/legacy/sections/lycee/lecturesGraphiques/sectionIntersectionDroitesAxes.js'),
  langageEvts: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionlangageEvts.js'),
  modeliserLoi: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionmodeliserLoi.js'),
  probabiliteLoi: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionprobabiliteLoi.js'),
  probabiliteArbre: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionprobabiliteArbre.js'),
  probaArbrePondere: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionprobaArbrePondere.js'),
  probabiliteCalculSimple: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionprobabiliteCalculSimple.js'),
  intersectionReunion: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionintersectionReunion.js'),
  probaCondTab: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionprobaCondTab.js'),
  nonEquiProba: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionnonEquiProba.js'),
  arbreDeuxEpreuvesIndep: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionarbreDeuxEpreuvesIndep.js'),
  probaTableau: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionprobaTableau.js'),
  vaLoiProba: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionvaLoiProba.js'),
  simulerVa: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionsimulerVa.js'),
  espeEcartTypeVa: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionespeEcartTypeVa.js'),
  tabDoubleEntree: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectiontabDoubleEntree.js'),
  diagrammeVenn: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectiondiagrammeVenn.js'),
  arbreProbaCond: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionarbreProbaCond.js'),
  probaLoiGeo: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionprobaLoiGeo.js'),
  BienaymeTchebychev: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionBienaymeTchebychev.js'),
  loiBernoulli: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionloiBernoulli.js'),
  espeVarBinomiale: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionespeVarBinomiale.js'),
  concentration: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionconcentration.js'),
  sommeVaIndep: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionsommeVaIndep.js'),
  epreuvesIndep: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionepreuvesIndep.js'),
  evtsIndep: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionevtsIndep.js'),
  ensemblePartie: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionensemblePartie.js'),
  projOrthoDroite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/ProduitScalaire/sectionprojOrthoDroite.js'),
  manipulerListe: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Python/sectionmanipulerListe.js'),
  utiliserFct: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Python/sectionutiliserFct.js'),
  instructionsListe: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Python/sectioninstructionsListe.js'),
  bouclePourListe: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Python/sectionbouclePourListe.js'),
  simulDeuxIssues: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Python/sectionsimulDeuxIssues.js'),
  courbe_canonique: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectioncourbe_canonique.js'),
  courbe_canonique2: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectioncourbe_canonique2.js'),
  canonique_courbe2: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectioncanonique_courbe2.js'),
  extremum_canonique: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectionextremum_canonique.js'),
  dvptverscanonique: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectiondvptverscanonique.js'),
  dvptverscanonique2: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectiondvptverscanonique2.js'),
  canonique_tabvar: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectioncanonique_tabvar.js'),
  courbe_signedelta: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectioncourbe_signedelta.js'),
  canonique_symetrie1: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectioncanonique_symetrie1.js'),
  canonique_symetrie2: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectioncanonique_symetrie2.js'),
  factorise_formedev: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectionfactorise_formedev.js'),
  forme_adaptee: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectionforme_adaptee.js'),
  racine_trinome: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectionracine_trinome.js'),
  egalite_fonctions: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectionegalite_fonctions.js'),
  inequation_degre2: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectioninequation_degre2.js'),
  signe_trinome: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectionsigne_trinome.js'),
  tabvar_trinome: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectiontabvar_trinome.js'),
  medianePetitsEffectifs: (): Promise<LegacySection> => import('src/legacy/sections/lycee/statistiques/sectionmedianePetitsEffectifs.js'),
  medianeQuartiles: (): Promise<LegacySection> => import('src/legacy/sections/lycee/statistiques/sectionmedianeQuartiles.js'),
  frequences: (): Promise<LegacySection> => import('src/legacy/sections/lycee/statistiques/sectionfrequences.js'),
  medianeQuartilesCourbeECC: (): Promise<LegacySection> => import('src/legacy/sections/lycee/statistiques/sectionmedianeQuartilesCourbeECC.js'),
  proportion: (): Promise<LegacySection> => import('src/legacy/sections/lycee/statistiques/sectionproportion.js'),
  moyenneEcartType: (): Promise<LegacySection> => import('src/legacy/sections/lycee/statistiques/sectionmoyenneEcartType.js'),
  paramStat_tableur: (): Promise<LegacySection> => import('src/legacy/sections/lycee/statistiques/sectionparamStat_tableur.js'),
  propDeProportion: (): Promise<LegacySection> => import('src/legacy/sections/lycee/statistiques/sectionpropDeProportion.js'),
  signe_affine: (): Promise<LegacySection> => import('src/legacy/sections/lycee/tableausignes/sectionsigne_affine.js'),
  prodquot_affine: (): Promise<LegacySection> => import('src/legacy/sections/lycee/tableausignes/sectionprodquot_affine.js'),
  signe_prodquot: (): Promise<LegacySection> => import('src/legacy/sections/lycee/tableausignes/sectionsigne_prodquot.js'),
  signe_courbe1: (): Promise<LegacySection> => import('src/legacy/sections/lycee/tableausignes/sectionsigne_courbe1.js'),
  signe_courbe2: (): Promise<LegacySection> => import('src/legacy/sections/lycee/tableausignes/sectionsigne_courbe2.js'),
  signeCourbe: (): Promise<LegacySection> => import('src/legacy/sections/lycee/tableausignes/sectionsigneCourbe.js'),
  tauxEvolution: (): Promise<LegacySection> => import('src/legacy/sections/lycee/taux_evolution/sectiontauxEvolution.js'),
  tauxSuccessifs: (): Promise<LegacySection> => import('src/legacy/sections/lycee/taux_evolution/sectiontauxSuccessifs.js'),
  tauxReciproque: (): Promise<LegacySection> => import('src/legacy/sections/lycee/taux_evolution/sectiontauxReciproque.js'),
  tauxMoyen: (): Promise<LegacySection> => import('src/legacy/sections/lycee/taux_evolution/sectiontauxMoyen.js'),
  xPuissanceNEgalK: (): Promise<LegacySection> => import('src/legacy/sections/lycee/taux_evolution/sectionxPuissanceNEgalK.js'),
  evolutionTableur: (): Promise<LegacySection> => import('src/legacy/sections/lycee/taux_evolution/sectionevolutionTableur.js'),
  recopiageFormule: (): Promise<LegacySection> => import('src/legacy/sections/lycee/taux_evolution/sectionrecopiageFormule.js'),
  tauxCoefMult: (): Promise<LegacySection> => import('src/legacy/sections/lycee/taux_evolution/sectiontauxCoefMult.js'),
  coefVectColineaires: (): Promise<LegacySection> => import('src/legacy/sections/lycee/vecteurs/sectioncoefVectColineaires.js'),
  colinearite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/vecteurs/sectioncolinearite.js'),
  appliColinearite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/vecteurs/sectionappliColinearite.js'),
  // Pour les Terminales
  compositionSchema: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctions_reference/sectioncompositionSchema.js'),
  compositionCourbes: (): Promise<LegacySection> => import('src/legacy/sections/lycee/fonctions_reference/sectioncompositionCourbes.js'),
  algoDijkstra: (): Promise<LegacySection> => import('src/legacy/sections/lycee/graphe/sectionalgoDijkstra.js'),
  asymptote: (): Promise<LegacySection> => import('src/legacy/sections/lycee/limites/sectionasymptote.js'),
  limiteComparaison: (): Promise<LegacySection> => import('src/legacy/sections/lycee/limites/sectionlimiteComparaison.js'),
  limites_ln_exp: (): Promise<LegacySection> => import('src/legacy/sections/lycee/limites/sectionlimites_ln_exp.js'),
  limites_ln1: (): Promise<LegacySection> => import('src/legacy/sections/lycee/limites/sectionlimites_ln1.js'),
  limites_ln2: (): Promise<LegacySection> => import('src/legacy/sections/lycee/limites/sectionlimites_ln2.js'),
  limites_exp1: (): Promise<LegacySection> => import('src/legacy/sections/lycee/limites/sectionlimites_exp1.js'),
  limites_exp2: (): Promise<LegacySection> => import('src/legacy/sections/lycee/limites/sectionlimites_exp2.js'),
  limiteFonction: (): Promise<LegacySection> => import('src/legacy/sections/lycee/limites/sectionlimiteFonction.js'),
  limiteComposee: (): Promise<LegacySection> => import('src/legacy/sections/lycee/limites/sectionlimiteComposee.js'),
  limiteGraphique: (): Promise<LegacySection> => import('src/legacy/sections/lycee/limites/sectionlimiteGraphique.js'),
  probaconditionnelles: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionprobaconditionnelles.js'),
  probaconditionnelles2: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionprobaconditionnelles2.js'),
  probaCondSuite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionprobaCondSuite.js'),
  combinaisons: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectioncombinaisons.js'),
  denombrementSimple: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectiondenombrementSimple.js'),
  denombrementArbre: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectiondenombrementArbre.js'),
  combinaisonsMultiples: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectioncombinaisonsMultiples.js'),
  loiBinomiale: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionloiBinomiale.js'),
  fluctuationBinomiale: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionfluctuationBinomiale.js'),
  calculPrbBinomiale: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectioncalculPrbBinomiale.js'),
  inverseBinomiale: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectioninverseBinomiale.js'),
  statsDeuxVariables: (): Promise<LegacySection> => import('src/legacy/sections/lycee/statistiques/sectionstatsDeuxVariables.js'),
  SuiteTermeGeneral: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionSuiteTermeGeneral.js'),
  ArithmeticoGeo: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionArithmeticoGeo.js'),
  natureSuite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionnatureSuite.js'),
  tauxEvolutionSuite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectiontauxEvolutionSuite.js'),
  limiteArithGeo: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionlimiteArithGeo.js'),
  sommeTermesSuite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionsommeTermesSuite.js'),
  calculTerme: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectioncalculTerme.js'),
  conjectureLimite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionconjectureLimite.js'),
  determinerSeuil: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectiondeterminerSeuil.js'),
  thComparaison: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionthComparaison.js'),
  convergenceSuite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionconvergenceSuite.js'),
  limiteSuite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionlimiteSuite.js'),
  expoSuites: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionexpoSuites.js'),
  QCMSuiteInt: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionQCMSuiteInt.js'),
  sensVarSuite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionsensVarSuite.js'),
  algoSeuilSuite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Python/sectionalgoSeuilSuite.js'),
  loinormaleAire: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionloinormaleAire.js'),
  loinormaleCalculs: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionloinormaleCalculs.js'),
  loinormaleSigma: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionloinormaleSigma.js'),
  loinormalePrbSigma: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionloinormalePrbSigma.js'),
  loiExpoTrouverLambda: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionloiExpoTrouverLambda.js'),
  loiExpoCalculProba: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionloiExpoCalculProba.js'),
  loiExpoEsperance: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionloiExpoEsperance.js'),
  loiExpoProbaCond: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionloiExpoProbaCond.js'),
  espeVarLoisIndep: (): Promise<LegacySection> => import('src/legacy/sections/lycee/probabilites/sectionespeVarLoisIndep.js'),
  equationContinuite: (): Promise<LegacySection> => import('src/legacy/sections/lycee/tableauvariations/sectionequationContinuite.js'),
  delta: (): Promise<LegacySection> => import('src/legacy/sections/lycee/seconddegre/sectiondelta.js'),
  squelettederivation: (): Promise<LegacySection> => import('src/legacy/sections/lycee/derivation/sectionsquelettederivation.js'),
  squelettetangente: (): Promise<LegacySection> => import('src/legacy/sections/lycee/derivation/sectionsquelettetangente.js'),
  600: (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section600.js'),
  '600R': (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section600R.js'),
  601: (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section601.js'),
  602: (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section602.js'),
  '602bis': (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section602bis.js'),
  '602prod': (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section602prod.js'),
  '602quot': (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section602quot.js'),
  '602somme': (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section602somme.js'),
  '602ter': (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section602ter.js'),
  603: (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section603.js'),
  604: (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section604.js'),
  '605R': (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section605R.js'),
  '606R': (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section606R.js'),
  '606Rb': (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section606Rb.js'),
  610: (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section610.js'),
  611: (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section611.js'),
  630: (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section630.js'),
  650: (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section650.js'),
  651: (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section651.js'),
  652: (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section652.js'),
  // existe plus
  // '670b': (): Promise<LegacySection> => import('src/legacy/sections/lycee/complexes/section670b.js'),
  750: (): Promise<LegacySection> => import('src/legacy/sections/lycee/integrales/section750.js'),
  751: (): Promise<LegacySection> => import('src/legacy/sections/lycee/integrales/section751.js'),
  752: (): Promise<LegacySection> => import('src/legacy/sections/lycee/integrales/section752.js'),
  753: (): Promise<LegacySection> => import('src/legacy/sections/lycee/integrales/section753.js'),
  Suites2: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionSuites2.js'),
  Suites3: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionSuites3.js'),
  V700: (): Promise<LegacySection> => import('src/legacy/sections/videoprojection/sectionV700.js'),
  V701: (): Promise<LegacySection> => import('src/legacy/sections/videoprojection/sectionV701.js'),
  V702: (): Promise<LegacySection> => import('src/legacy/sections/videoprojection/sectionV702.js'),
  V703: (): Promise<LegacySection> => import('src/legacy/sections/videoprojection/sectionV703.js'),
  V704: (): Promise<LegacySection> => import('src/legacy/sections/videoprojection/sectionV704.js'),
  V705: (): Promise<LegacySection> => import('src/legacy/sections/videoprojection/sectionV705.js'),
  V707: (): Promise<LegacySection> => import('src/legacy/sections/videoprojection/sectionV707.js'),
  Vboulier: (): Promise<LegacySection> => import('src/legacy/sections/videoprojection/sectionVboulier.js'),
  tableurbrevet2013: (): Promise<LegacySection> => import('src/legacy/sections/outils/tableur/sectiontableurbrevet2013.js'),
  exo_mep: (): Promise<LegacySection> => import('src/legacy/sections/outils/mep/sectionexo_mep.js'),
  aide_mep: (): Promise<LegacySection> => import('src/legacy/sections/outils/mep/sectionaide_mep.js'),
  ancienexo_mep: (): Promise<LegacySection> => import('src/legacy/sections/outils/mep/sectionancienexo_mep.js'),
  droitegraduee: (): Promise<LegacySection> => import('src/legacy/sections/primaire/droitegraduee/sectiondroitegraduee.js'),
  dglireabscisse: (): Promise<LegacySection> => import('src/legacy/sections/primaire/droitegraduee/sectiondglireabscisse.js'),
  dglireabscisse2: (): Promise<LegacySection> => import('src/legacy/sections/primaire/droitegraduee/sectiondglireabscisse2.js'),
  dgplacerpoint1: (): Promise<LegacySection> => import('src/legacy/sections/primaire/droitegraduee/sectiondgplacerpoint1.js'),
  suitedecimaux: (): Promise<LegacySection> => import('src/legacy/sections/primaire/droitegraduee/sectionsuitedecimaux.js'),
  paralleles: (): Promise<LegacySection> => import('src/legacy/sections/primaire/paralleles/sectionparalleles.js'),
  parallelesfaisceau: (): Promise<LegacySection> => import('src/legacy/sections/primaire/paralleles/sectionparallelesfaisceau.js'),
  parallelesremed1: (): Promise<LegacySection> => import('src/legacy/sections/primaire/paralleles/sectionparallelesremed1.js'),
  cm2exN1_13: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN1_13.js'),
  cm2exN1_16: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN1_16.js'),
  cm2exN1_18: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN1_18.js'),
  cm2exN1_23: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN1_23.js'),
  cm2exN1_26: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN1_26.js'),
  cm2exN1_31: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN1_31.js'),
  cm2exN1_32: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN1_32.js'),
  cm2exN1_33: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN1_33.js'),
  cm2exN2_12: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN2_12.js'),
  cm2exN4_22: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN4_22.js'),
  cm2exN4_41: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN4_41.js'),
  cm2exN4_65: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN4_65.js'),
  cm2exN4_68: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN4_68.js'),
  cm2exN4_76: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exN4_76.js'),
  cm2exA1_12: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exA1_12.js'),
  cm2exA1_11: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exA1_11.js'),
  cm2exM3_11: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exM3_11.js'),
  cm2exM3_12: (): Promise<LegacySection> => import('src/legacy/sections/primaire/cahiercm2/sectioncm2exM3_12.js'),
  squelettemtg32_Placer_Point_Dans_Rep: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Placer_Point_Dans_Rep.js'),
  squelettemtg32_Tracer_Droite1: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Tracer_Droite1.js'),
  squelettemtg32_Tracer_Droite2: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Tracer_Droite2.js'),
  squelettemtg32_Trouver_Eq_Droite1: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Trouver_Eq_Droite1.js'),
  squelettemtg32_Trouver_Eq_Droite2: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Trouver_Eq_Droite2.js'),
  squelettemtg32_Ex_Calcul: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Ex_Calcul.js'),
  squelettemtg32_Fact_Param: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Fact_Param.js'),
  squelettemtg32_Calc_Param: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param.js'),
  squelettemtg32_Calc_Param_MathQuill: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_MathQuill.js'),
  squelettemtg32_Calc_Param_1_Edit: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_1_Edit.js'),
  squelettemtg32_Calc_Param_2_Edit: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_2_Edit.js'),
  squelettemtg32_Calc_Param_Apres_Oui_Non: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_Apres_Oui_Non.js'),
  squelettemtg32_Eq_Param_2_Inc: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Eq_Param_2_Inc.js'),
  squelettemtg32_Calc_Param_Multi_Edit: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_Multi_Edit.js'),
  squelettemtg32_Calc_Param_Multi_Edit_2_Etapes: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_Multi_Edit_2_Etapes.js'),
  squelettemtg32_Calc_Param_Multi_Etapes: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_Multi_Etapes.js'),
  squelettemtg32_Calc_Multi_Edit_Multi_Etapes: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Multi_Edit_Multi_Etapes.js'),
  squelettemtg32_Calc_Multi_Edit: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Multi_Edit.js'),
  squelettemtg32_Calc_Param_Par_Etapes: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_Par_Etapes.js'),
  squelettemtg32_Calc_Param_Multi_Edit_Bloc: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_Multi_Edit_Bloc.js'),
  squelettemtg32_Calc_Vect_Multi_Edit: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Vect_Multi_Edit.js'),
  squelettemtg32_Calc_Param_Vect: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_Vect.js'),
  squelettemtg32_Calc_Param_Vect_Prosca: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_Vect_Prosca.js'),
  squelettemtg32_Calc_Param_Multi_Edit_Apres_Interm: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_Multi_Edit_Apres_Interm.js'),
  squelettemtg32_Validation_Interne_Param: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Validation_Interne_Param.js'),
  squelettemtg32_Systeme_Param: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Systeme_Param.js'),
  squelettemtg32_Systeme_33: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Systeme_33.js'),
  squelettemtg32_Calc_Param_Affine_MathQuill: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_Affine_MathQuill.js'),
  squelettemtg32_Calc_Param_MathQuill_Sans_Entete: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Param_MathQuill_Sans_Entete.js'),
  squelettemtg32_Eq_Param_Sol_Mult: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Eq_Param_Sol_Mult.js'),
  squelettemtg32_Eq_Param_Sol_Mult_1_Edit: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Eq_Param_Sol_Mult_1_Edit.js'),
  squelettemtg32_Calc_Ecriture_Scientifique: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Ecriture_Scientifique.js'),
  squelettemtg32_Reduc_Frac: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Reduc_Frac.js'),
  squelettemtg32_Calc_Frac: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Calc_Frac.js'),
  squelettemtg32_Eq_Param: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Eq_Param.js'),
  squelettemtg32_Eq_Param_MathQuill: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Eq_Param_MathQuill.js'),
  squelettemtg32_Ecriture_Exp_Complexe: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Ecriture_Exp_Complexe.js'),
  squelettemtg32_Eq_Calcul_Formel: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Eq_Calcul_Formel.js'),
  squelettemtg32_Ineq_Param: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Ineq_Param.js'),
  squelettemtg32_Ineq_Param_1_Edit: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Ineq_Param_1_Edit.js'),
  squelettemtg32_Ineq_Param_Apres_Ens_Def: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Ineq_Param_Apres_Ens_Def.js'),
  squelettemtg32_Eq_Param_Sol_Mult_Apres_Ens_Def: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Eq_Param_Sol_Mult_Apres_Ens_Def.js'),
  squelettemtg32_Eq_Param_Vect: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Eq_Param_Vect.js'),
  squelettemtg32_Deriv_Param: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Deriv_Param.js'),
  squelettemtg32_Reduc_Meme_Denom_Param: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Reduc_Meme_Denom_Param.js'),
  squelettemtg32_Somme_Frac: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Somme_Frac.js'),
  squelettemtg32_Question_Oui_Non_Param: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Question_Oui_Non_Param.js'),
  squelettemtg32_Vecteurs_Sur_Axe_Placer_M: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Vecteurs_Sur_Axe_Placer_M.js'),
  squelettemtg32_Vecteurs_Placer_M_Par_CL: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Vecteurs_Placer_M_Par_CL.js'),
  squelettemtg32_Reduire_Eq_Droite_Param: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Reduire_Eq_Droite_Param.js'),
  squelettemtg32_NbAntecedents_Captk: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_NbAntecedents_Captk.js'),
  squelettemtg32_Ex_Const: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Ex_Const.js'),
  squelettemtg32_Ex_Const_Param: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Ex_Const_Param.js'),
  squelettemtg32_Ex_Const_Param_Apres_Question: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Ex_Const_Param_Apres_Question.js'),
  squelettemtg32_Ex_Const_Param_NbObjMax: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Ex_Const_Param_NbObjMax.js'),
  squelettemtg32_Op_Matrices_Param: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_Op_Matrices_Param.js'),
  diviseurs_Entier: (): Promise<LegacySection> => import('src/legacy/sections/lycee/arithmetique/sectiondiviseurs_Entier.js'),
  espace_repere1: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectionespace_repere1.js'),
  espace_repere5: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectionespace_repere5.js'),
  espace_repere6: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectionespace_repere6.js'),
  espace_repere7: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectionespace_repere7.js'),
  espace_repere9: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectionespace_repere9.js'),
  Alignes: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectionAlignes.js'),
  squeletteatome: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsqueletteatome.js'),
  equation_dichotomie: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Equations/sectionequation_dichotomie.js'),
  dte_dte: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectiondte_dte.js'),
  dte_plan_cart: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectiondte_plan_cart.js'),
  theoremedepythagore: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/sectiontheoremedepythagore.js'),
  reduirecomplexe: (): Promise<LegacySection> => import('src/legacy/sections/college/quatrieme/sectionreduirecomplexe.js'),
  ProduitScalaireModele: (): Promise<LegacySection> => import('src/legacy/sections/lycee/ProduitScalaire/sectionProduitScalaireModele.js'),
  ProduitScalaire3pts: (): Promise<LegacySection> => import('src/legacy/sections/lycee/ProduitScalaire/sectionProduitScalaire3pts.js'),
  ProduitScalaire4pts: (): Promise<LegacySection> => import('src/legacy/sections/lycee/ProduitScalaire/sectionProduitScalaire4pts.js'),
  ProduitScalaireOrtho: (): Promise<LegacySection> => import('src/legacy/sections/lycee/ProduitScalaire/sectionProduitScalaireOrtho.js'),
  ProduitScalaireOrthoInc: (): Promise<LegacySection> => import('src/legacy/sections/lycee/ProduitScalaire/sectionProduitScalaireOrthoInc.js'),
  point_dans_plan: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectionpoint_dans_plan.js'),
  droites_paralleles: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Espace/sectiondroites_paralleles.js'),
  calculatice: (): Promise<LegacySection> => import('src/legacy/sections/outils/calculatice/sectioncalculatice.js'),
  exconst: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectionexconst.js'),
  exconstParam: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectionexconstParam.js'),
  excalc: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectionexcalc.js'),
  excalcmathquill: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectionexcalcmathquill.js'),
  eq_Sol_Mult_1_Edit: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectioneq_Sol_Mult_1_Edit.js'),
  ex_Calc_Multi_Edit_Apres_Interm: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectionex_Calc_Multi_Edit_Apres_Interm.js'),
  ex_Calc_Multi_Edit: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectionex_Calc_Multi_Edit.js'),
  ex_Calc_Multi_Edit_ML: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectionex_Calc_Multi_Edit_ML.js'),
  ex_Calc_Multi_Edit_Multi_Etapes: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectionex_Calc_Multi_Edit_Multi_Etapes.js'),
  ex_Validation_Interne: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectionex_Validation_Interne.js'),
  ineq: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectionineq.js'),
  ex_Calc_Vect: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectionex_Calc_Vect.js'),
  lecteurmtg32: (): Promise<LegacySection> => import('src/legacy/sections/outils/mtg32/sectionlecteurmtg32.js'),
  lecteuriep: (): Promise<LegacySection> => import('src/legacy/sections/outils/iep/sectionlecteuriep.js'),
  lecteuriepparurl: (): Promise<LegacySection> => import('src/legacy/sections/outils/iep/sectionlecteuriepparurl.js'),
  Position: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Cycle_3/Comprendre_les_nombres_decimaux/sectionPosition.js'), //  Quelle est le chiffre des ...?
  Encadrement: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Cycle_3/Comprendre_les_nombres_decimaux/sectionEncadrement.js'), //  Encadrer un nombre entre deux ...?
  Approche: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Cycle_3/Comprendre_les_nombres_decimaux/sectionApproche.js'), //  Encadrer un nombre entre deux ...?
  LienPositionnel: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Cycle_3/Comprendre_les_nombres_decimaux/sectionLienPositionnel.js'), //  Encadrer un nombre entre deux ...?
  NombreEnChiffres: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Cycle_3/Comprendre_les_nombres_decimaux/sectionNombreEnChiffres.js'), //  Ecrire un nombre en chiffres.
  Tables: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Cycle_3/Calcul_mental/sectionTables.js'), //  Encadrer un nombre entre deux ...?
  Produit: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Cycle_3/Calcul_mental/sectionProduit.js'), //  Ecrire un nombre sous la forme d’un produit ...?
  MultiplierPar10: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Cycle_3/Calcul_mental/sectionMultiplierPar10.js'), //  Multipler diviser par 10, 100, 100
  OperationTrou: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Cycle_3/Calcul_mental/sectionOperationTrou.js'), //  Tables de multiplication?
  Conversion1: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Cycle_3/Grandeur_et_mesure/sectionConversion1.js'), //  Lien entre les multiples et l’unité de base?
  Diviseurs: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Cycle_3/Calculer_avec_les_nombres_rationnels/sectionDiviseurs.js'), //  Donner la liste des diviseurs d’un nombre
  Pourcentages1: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Cycle_3/Resoudre_un_probleme/sectionPourcentages1.js'), //  Donner la liste des diviseurs d’un nombre
  squelettemtg32_XJ: (): Promise<LegacySection> => import('src/legacy/sections/squelettes/sectionsquelettemtg32_XJ.js'), // Enoncé paramétrable
  recur: (): Promise<LegacySection> => import('src/legacy/sections/lycee/suites/sectionrecur.js'),
  python_delta: (): Promise<LegacySection> => import('src/legacy/sections/lycee/Python/sectionpython_delta.js'),
  PresentationCalculs: (): Promise<LegacySection> => import('src/legacy/sections/EssaisXavier/Inhibition/PresentationCalculs/sectionPresentationCalculs.js'),
  duree01: (): Promise<LegacySection> => import('src/legacy/sections/college/sixieme/duree/sectionduree01.js'), // aj 2021-09-10

  // les sections v2
  TestMathlive: (): Promise<Section> => import('src/sections/sectionTestMathfields'), // aj 2024-03-23
  Exemple1: (): Promise<Section> => import('src/sections/sectionExemple1'), // aj 2023-02-09
  Exemple2: (): Promise<Section> => import('src/sections/sectionExemple2'), // aj 2023-09-13
  Exemple3: (): Promise<Section> => import('src/sections/sectionExemple3'), // aj 2023-09-25
  Exemple4: (): Promise<Section> => import('src/sections/sectionExemple4'), // aj 2023-11-09
  ChoisisTaFonction: (): Promise<Section> => import('src/sections/sectionChoisisTaFonction'), // aj 2024-02-23
  SectionV2Exemple: (): Promise<Section> => import('src/sections/sectionV2Exemple'), // aj 2024-05-29
  ChangementBase: (): Promise<Section> => import('src/sections/lycee/arithmetique/sectionChangementBase') // aj 2024-08-05
}

// Les imports dynamiques sont très restrictifs avec vite, cf https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
// on va donc se constituer une liste ici et piocher dedans
// cf https://debugah.com/solved-vite2-error-unknown-variable-dynamic-import-26487/
// => marche pas, on génère notre liste d’import ci-dessus
// const sections = import.meta.glob('../sections/**/section*.js')
// console.log('sections', sections)

/**
 * Charge une section
 * @param {string} section
 * @return {Promise<Section>}
 */
export default async function loadSection (section: string): Promise<Section | LegacySection> {
  const loader = sections[section]
  if (loader == null) throw Error(`Section ${section} inconnue`)
  return loader()
}

export function isLegacySection (section: Section | LegacySection): section is LegacySection {
  return ('default' in section && typeof section.default === 'function')
}

/**
 * retourne les exports d’une section V1 (la fonction principale sera la propriété default de l’objet retourné)
 * @param section
 */
export async function loadSectionV1 (section: string): Promise<LegacySection> {
  const loader = sections[section]
  if (loader == null) throw Error(`Section ${section} inconnue`)
  const module = await loader()
  if (isLegacySection(module)) {
    return module
  }
  const msg = typeof module.enonce === 'function'
    ? `La section ${section} est en version 2, elle ne peut pas être affiché par le moteur version 1`
    : `L’export par défaut de la section ${section} n’est pas une fonction`
  throw Error(msg)
}

/**
 * Retourne la liste des sections
 */
export function getSectionsList (): string[] {
  return Object.keys(sections)
}

/**
 * true si la chaine passée en paramêtre est une section valide
 * @param section
 */
export function sectionExists (section: string): boolean {
  return (section in sections)
}

/**
 * retourne dans une promesse les paramètres de la section et l'éventuelle fct upgradeParameters
 * @param section
 */
export async function fetchParameters (section: string): Promise<{
  parameters: SectionParameters,
  updateParameters?: (params: ParamsValues) => void
  validateParameters?:ValidateParametersFn
}> {
  const module = await loadSection(section)
  if (isLegacySection(module)) {
    const { convertParametres } = await import('./convert1to2')
    const parameters = convertParametres(module.params)
    const updateParameters = module.upgradeParametres as (params: ParamsValues) => void
    return { parameters, updateParameters }
  }
  const { parameters, updateParameters, validateParameters } = module
  return { parameters, updateParameters, validateParameters }
}

/**
 * retourne dans une promesse l’objet contenant la liste des évaluations [peRef, pe]
 * @param section
 */
export async function fetchEvaluations (section: string): Promise<SectionEvaluations> {
  const module = await loadSection(section)
  if (isLegacySection(module)) {
    const { convertPes } = await import('./convert1to2')
    return convertPes(module.params)
  }
  return module.evaluations ?? {}
}

/**
 * retourne dans une promesse le tableau des phrases d’état de la section correspondantes aux peRefs
 * @param section
 * @param peRefs
 */
export async function pesList (section: string, peRefs: string[]): Promise<string[]> {
  const evaluations = await fetchEvaluations(section)
  return peRefs.map(peRef => evaluations[peRef] ?? '')
}
